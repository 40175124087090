import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          childImageSharp{
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`

export default function Template({
    data,
}) {
    const { markdownRemark: post} = data
    const { title } = useSiteMetadata()

    return (
      <Layout>
        <Helmet title={`${title} - ${post.frontmatter.title}`} />
        <div className="blog-post make__grid">
          <h1>{post.frontmatter.title}</h1>
          <div className="blog-post__published">{ post.frontmatter.date }</div>
          <div className="blog-post__image"><Image sizes={post.frontmatter.featuredImage.childImageSharp.sizes} /></div>
          <div className="blog-post__content" dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </Layout>
    )
}